import MusicNoteIcon from '@mui/icons-material/MusicNote';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SchoolIcon from '@mui/icons-material/School';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import RocketIcon from '@mui/icons-material/DeveloperMode';
import ComputerIcon from '@mui/icons-material/DeveloperMode';
import PhysicsIcon from '@mui/icons-material/DeveloperMode';
import MoneyIcon from '@mui/icons-material/DeveloperMode';

export const logo = 'https://i.ibb.co/s9Qys2j/logo.png';

export const categories = [
  { name: 'MachineLearning', icon: <HomeIcon />, },
  { name: 'SpaceX', icon: <RocketIcon/>, },
  { name: 'AI', icon: <CodeIcon />, },
  { name: 'ReactJS', icon: <CodeIcon />, },
  { name: 'NextJS', icon: <CodeIcon />, },
  { name: 'Elon Musk', icon: <PhysicsIcon /> },
  { name: 'Astrophysics', icon: <SchoolIcon />, },
  { name: 'Raghav Mahajan', icon: <GraphicEqIcon />, },
  { name: 'Solar Storms by Raghav Mahajan', icon: <OndemandVideoIcon />, },
  { name: 'The Ranveer Show Hindi', icon: <SportsEsportsIcon />, },
  { name: 'Samay Raina', icon: <LiveTvIcon />, },
  { name: 'Dhruv Rathee', icon: <FitnessCenterIcon />, },
  { name: 'Earn Money Online ', icon: <MoneyIcon />, },
  { name: 'New Scam 2024', icon: <FaceRetouchingNaturalIcon />, },
  { name: 'Kapil Sharma Show', icon: <TheaterComedyIcon />, },
  { name: 'Gym', icon: <FitnessCenterIcon />, },
  { name: 'Crypto', icon: <DeveloperModeIcon />, },
];

export const demoThumbnailUrl = 'https://i.ibb.co/G2L2Gwp/API-Course.png';
export const demoChannelUrl = '/channel/UCmXmlB4-HJytD7wek0Uo97A';
export const demoVideoUrl = '/video/GDa8kZLNhJ4';
export const demoChannelTitle = 'JavaScript Mastery';
export const demoVideoTitle = 'Build and Deploy 5 JavaScript & React API Projects in 10 Hours - Full Course | RapidAPI';
export const demoProfilePicture = 'http://dergipark.org.tr/assets/app/images/buddy_sample.png'